import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { PortalRegistrationService } from '../modules/portal-registration/services/portal-registration.service';
@Directive({
  selector: '[validateTaxId]',
})
export class ValidateTaxIdDirective implements OnInit, OnDestroy {
  // directive to validate tax id with API
  @Output() ValidateTaxID = new EventEmitter<boolean>();
  subject: Subject<any> = new Subject();

  constructor(
    public el: ElementRef,
    private portalRegistrationService: PortalRegistrationService,
    private cdr: ChangeDetectorRef,
  ) {}

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const e = <any>event;
    const val = this.el.nativeElement.value;
    if (val.length > 0 && e.inputType !== 'deleteContentBackward') {
      this.subject.next('');
    }
  }

  ngOnInit() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      if (this.el.nativeElement.value.length === 10) {
        this.ValidateTaxId(this.el.nativeElement.value);
      }
    });
  }

  ValidateTaxId(TaxId: string) {
    this.portalRegistrationService.ValidateTaxID(TaxId).then((res) => {
      this.ValidateTaxID.emit(res[0]);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }
}
