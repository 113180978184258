export class Constants {
  public static readonly DateRangePlaceholderText = 'mm/dd/yyyy - mm/dd/yyyy';
  public static readonly TaxIdRegex = new RegExp(/^\d{2}-\d{7}$/);
  public static readonly ExcelExportKey = 'xlsx';
  public static readonly CsvExportKey = 'csv';
  public static readonly DocStatusOpen = 'opened';
  public static readonly DocRequestOpened = 'request opened';
  public static readonly DocReplyOpened = 'reply opened';
  public static readonly DocReplySent = 'reply sent';
  public static readonly DocRequestSent = 'request sent';
  public static readonly DocStatusReplyInProgress = 'reply in progress';
  public static readonly DocRequestDownloadBySender =
    'request downloaded by sender';
  public static readonly DocRequestDownloadByRecipient =
    'request downloaded by recipient';
  public static readonly DocStatusReplyDownloadBySender =
    'reply downloaded by sender';
  public static readonly DocStatusReplyDownloadByRecipient =
    'reply downloaded by recipient';
  public static readonly DocStatusDownloadedByRecipient =
    'downloaded by recipient';
  public static readonly DocStatusDownloadedBySender = 'downloaded by sender';
  public static readonly DocStatusAutoDelete = 'auto delete';
  public static readonly DocStatusRevisedAutoDeleteDate =
    'revised auto delete date';
  public static readonly DocStatusDeletedBySender = 'deleted by sender';
  public static readonly DocStatusDeletedByRecipient = 'deleted by recipient';
  public static readonly IsReAuthenticatedClaim = 'isReAuthenticated';
  public static readonly NameAndId = 'NameAndId';
  public static readonly Name = 'Name';
  public static readonly Id = 'Id';
}

export class TextKeyConstants {
  public static readonly SEFooterInfo = 'SecureExchange_FooterInfo';
  public static readonly SEPreviewInfo = 'SecureExchange_PreviewInfo';
  public static readonly SEAutoSaveInfo = 'SecureExchange_AutoSaveInfo';
  public static readonly SEDouSignPreviewInfo =
    'SecureExchange_Docusign_Preview_Message';
  public static readonly SEAutoDeleteInfo = 'SecureExchange_AutoDeleteInfo';
  public static readonly SEActivityInfo = 'SecureExchange_Activity_Message';
}

export class ErrorMessageKeyConstants {
  public static readonly SEDeleteDraftWarning = 'Delete_Draft_Exchange_Warning';
  public static readonly SEDeleteInboxWarning =
    'Delete_Inbox_Send_Exchange_Warning';
  public static readonly SEDeleteWarningTitle = 'Delete_Text';
  public static readonly SEDeleteSuccessMessage =
    'SecureExchange_Delete_Success_Message';
  public static readonly SESendSuccessMessage =
    'SecureExchange_Wizard_Send_Success';
  public static readonly SESenderWizardDocusignInfo =
    'SecureExchange_Wizard_Docusign_Info_Message';
  public static readonly SESenderWizardDocusignSaveMessge =
    'SecureExchange_Wizard_Docusign_Save_Message';
  public static readonly SESenderWizardDocusignUnsavedMessge =
    'SecureExchange_Wizard_Docusign_Unsaved_Message';
  public static readonly SESenderWizardSaveMessge =
    'SecureExchange_Wizard_Save_Message';
  public static readonly SESenderWizardCancelMessge =
    'SecureExchange_Wizard_Cancel_Message';
  public static readonly SESenderWizardSendMessge =
    'SecureExchange_Wizard_Send_Message';
  public static readonly SESenderDocusignRecipientsUpdatedMessage =
    'SecureExchange_Wizard_Docusign_Recipient_Update_Message';
  public static readonly SESenderSearchRecipientsMessage =
    'SecureExchange_Wizard_Search_Message';
}
