import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { PortalRegistrationService } from '../modules/portal-registration/services/portal-registration.service';

@Directive({
  selector: '[validateRtn]',
})
export class ValidateRtnDirective implements OnInit, OnDestroy {
  @Output() ValidateRTN = new EventEmitter<boolean>();
  subject: Subject<any> = new Subject();

  constructor(
    public el: ElementRef,
    private portalRegistrationService: PortalRegistrationService,
    private cdr: ChangeDetectorRef,
  ) {}

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const e = <any>event;
    const val = this.el.nativeElement.value;
    if (val.length > 0 && e.inputType !== 'deleteContentBackward') {
      this.subject.next('');
    }
  }

  ngOnInit() {
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      if (this.el.nativeElement.value.length === 9) {
        this.ValidateEmail(this.el.nativeElement.value);
      }
    });
  }

  ValidateEmail(rtn: string) {
    this.portalRegistrationService.ValidatePrimaryRTN(rtn).then((res) => {
      this.ValidateRTN.emit(res);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }
}
