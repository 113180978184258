import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[autocompleteOff]',
})
export class AutocompleteDirective implements OnInit {
  private _chrome =
    window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  private _edge = window.navigator.userAgent.toLowerCase().indexOf('edg') > -1;
  private _mozilla =
    window.navigator.userAgent.toLowerCase().indexOf('mozilla') > -1;
  private _safari =
    window.navigator.userAgent.toLowerCase().indexOf('safari') > -1;
  constructor(private _el: ElementRef) {}
  ngOnInit() {
    if (this._chrome || this._mozilla || this._safari) {
      this._el.nativeElement.setAttribute('autocomplete', 'off');
    }
    if (this._edge) {
      this._el.nativeElement.setAttribute('aria-autocomplete', 'none');
    }
  }
}
