import {
  Directive,
  ElementRef,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import { UtilitiesService } from '../services/utilities.service';

@Directive({
  selector: '[width]',
})
export class WidthDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private utils: UtilitiesService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.utils.windowWidth.set((event.target as Window).innerWidth);
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.clientWidth > 400) {
      this.utils.windowWidth.set(this.el.nativeElement.clientWidth);
    }
  }
}
