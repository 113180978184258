import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../infrastructure/authentication/authentication.service';
import { UserService } from '../services/user.service';
import { GeneralService } from '../services/general.service';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../services/utilities.service';
import { MessagePanelService } from '../modules/common-components/message-panel/services/message-panel.service';
import { UserManagementService } from '../modules/user-management/services/user-management.service';

interface HelpLinks {
  text: string;
  url: string;
  sortOrder: number;
}
@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  userSubscription: Subscription = new Subscription();
  helpLinks: HelpLinks[] = [];
  isUserDropdownOpen = false;
  isHelpDropdownOpen = false;
  userName = '';
  userRole = '';
  userIcon: string = this.transform(this.userName);
  fullName: string | undefined;
  shortName!: string;
  supportDropdownList: any[] = [
    {
      id: 2,
      label: 'FAQ',
      icon: 'faq',
      hasDropdown: false,
      url: '/faq',
    },
    {
      id: 2,
      label: 'Support',
      icon: 'support',
      hasDropdown: false,
    },
  ];
  isStickyHeader = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly userService: UserService,
    private readonly generalService: GeneralService,
    private readonly cdr: ChangeDetectorRef,
    private readonly utils: UtilitiesService,
    private readonly messagePanelService: MessagePanelService,
    private readonly userManagementService: UserManagementService,
  ) {}

  async ngOnInit() {
    this.userSubscription = this.userService.user$.subscribe((user) => {
      if (user) {
        this.userName = user.userName;
        this.userRole = user.userRole;
        this.fullName = user.fullName;
        const splitedFullName = user.fullName!.split(' ');
        this.shortName =
          splitedFullName[0].slice(0, 1) +
          splitedFullName[splitedFullName.length - 1].slice(0, 1);
        this.cdr.detectChanges();
      }
    });
    await this.GetHelpLinks();

    const supportUrlKey = 'SupportLink';
    const supportUrlValue = this.helpLinks.find(
      (item: any) => item.text === supportUrlKey,
    );

    if (supportUrlValue) {
      this.supportDropdownList.find(
        (item: any) => item.label === 'Support',
      )!.url = supportUrlValue.url;
    }
    this.userSubscription.add(
      this.utils.isStickyHeader.subscribe((res) => {
        this.isStickyHeader = res;
        this.cdr.detectChanges();
      }),
    );
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  ChangePassword() {
    this.authService.ChangePassword().subscribe({
      next: (result) => {
        this.userManagementService.CaptureChangedPasswordForCurrentUserAsync();
        this.messagePanelService.ShowSuccessMessages(
          this.generalService.GetErrorMessage('Password_Change'),
        );
      },
    });
  }

  Logout(): void {
    this.authService.ExternalLogout();
  }

  transform(value: string): string {
    if (!value) {
      return '';
    }
    const nameArray = value.split(' ', 2);
    let nameIcon = '';
    nameArray.forEach(function (value) {
      nameIcon += value.split('')[0];
    });
    return nameIcon;
  }

  async GetHelpLinks() {
    const linkKeys = {
      PA: { linkName: 'PAPortalSummaryLink', sortOrder: 6 },
      ACH: { linkName: 'ACHPortalSummaryLink', sortOrder: 5 },
      TPS: { linkName: 'TPSPortalSummaryLink', sortOrder: 3 },
      ODFI: { linkName: 'ODFIPortalSummaryLink', sortOrder: 1 },
      RDFI: { linkName: 'RDFIPortalSummaryLink', sortOrder: 2 },
      TPSP: { linkName: 'TPSPPortalSummaryLink', sortOrder: 4 },
      Support: { linkName: 'SupportLink', sortOrder: 7 },
    };

    const linkTexts: { [key: string]: string } = {
      [linkKeys.PA.linkName]: 'PA Portal Instructions',
      [linkKeys.ACH.linkName]: 'ACH Operator Portal Instructions',
      [linkKeys.TPS.linkName]: 'TPS Portal Instructions',
      [linkKeys.ODFI.linkName]: 'ODFI Portal Instructions',
      [linkKeys.RDFI.linkName]: 'RDFI Portal Instructions',
      [linkKeys.TPSP.linkName]: 'TPSP Portal Instructions',
    };

    let keys = this.userService.isNacha()
      ? ''
      : linkKeys.Support.linkName + ',';
    if (
      this.userService.isAchOperatorAdmin() ||
      this.userService.isAchOperatorUser()
    ) {
      keys += linkKeys.ACH.linkName;
    } else if (this.userService.isOdfi()) {
      keys += linkKeys.ODFI.linkName;
    } else if (this.userService.isRdfi()) {
      keys += linkKeys.RDFI.linkName;
    } else if (this.userService.isTps()) {
      keys += linkKeys.TPS.linkName;
    } else if (this.userService.isTpsp()) {
      keys += linkKeys.TPSP.linkName;
    } else if (this.userService.isPa()) {
      keys += linkKeys.PA.linkName;
    } else if (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser()
    ) {
      keys = Object.values(linkKeys)
        .map((item) => item.linkName)
        .join(',');
    }

    const response = await this.generalService.GetConfigsAsync(keys);
    this.helpLinks = response.map((item: any) => {
      return {
        url: item.value,
        text: linkTexts[item.key] || item.key,
        sortOrder: Object.values(linkKeys).find((f) => f.linkName === item.key)!
          .sortOrder,
      };
    });
    this.helpLinks.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
    if (this.userService.isNachaAdmin() || this.userService.isNachaUser()) {
      const portalInstructionObjForNacha: any = {
        id: 1,
        label: 'Portal Instruction',
        icon: 'instructions',
        activeIcon: 'instructions-lite',
        hasDropdown: true,
        isDropdownActive: false,
        dropdownList: this.helpLinks,
      };
      this.supportDropdownList.unshift(portalInstructionObjForNacha);
    } else {
      const portalInstructionObjForOther: any = {
        id: 1,
        label: this.helpLinks[0].text,
        icon: 'instructions',
        activeIcon: 'instructions-lite',
        hasDropdown: false,
        url: this.helpLinks[0].url,
      };
      this.supportDropdownList.unshift(portalInstructionObjForOther);
    }
  }

  CloseSupportDropdown() {
    this.isHelpDropdownOpen = false;
    if (this.userService.isNachaAdmin() || this.userService.isNachaUser()) {
      this.supportDropdownList[0].isDropdownActive = false;
    }
  }

  ToggleChildDropdown(index: number) {
    if (this.supportDropdownList[index].hasDropdown) {
      this.supportDropdownList[index].isDropdownActive =
        !this.supportDropdownList[index].isDropdownActive;
      this.cdr.detectChanges();
    }
  }

  GoToPortalInstructions(url: string) {
    window.open(url, '_blank');
  }
}
