import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalConfiguration } from 'src/app/models/ModalConfiguration';
import { UserSessionService } from 'src/app/infrastructure/authentication/user-session.service';
import { Subscription } from 'rxjs';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'user-session-modal',
  templateUrl: './user-session-modal.component.html',
  styleUrls: ['./user-session-modal.component.scss'],
})
export class UserSessionModalComponent implements OnInit {
  userSessionSubscription: Subscription = new Subscription();
  userSessionThreshold = 120;
  timeRemaining = '00:00';

  constructor(
    private userSessionService: UserSessionService,
    private cdr: ChangeDetectorRef,
    private utils: UtilitiesService,
  ) {}

  ngOnInit() {
    this.userSessionSubscription.unsubscribe();
    this.userSessionService.sessionTimeRemaining$.subscribe((time) => {
      if (time <= this.userSessionThreshold) {
        this.isModalOpen = true;
        this.timeRemaining = this.utils.ToFormattedTime(time);
      }
      if (time < 1) {
        this.userSessionService.Logout();
        this.userSessionSubscription.unsubscribe();
        this.isModalOpen = false;
      }
      this.cdr.detectChanges();
    });
  }

  isModalOpen = false;

  modalConfiguration: ModalConfiguration = {
    header: 'Session Timeout',
    height: 300,
    width: 450,
    button2: {
      text: 'Extend Session',
      buttonClass: 'button button-dark  me-2',
      callback: () => {
        this.userSessionService.StartSessionCountdown();
        this.isModalOpen = false;
      },
    },
    includeCancelButton: false,
    cancelButtonText: 'Cancel',
    button1: {
      text: 'Logout',
      buttonClass: 'button button-light me-2',
      callback: () => {
        this.userSessionService.Logout();
        this.isModalOpen = false;
      },
      disableCallback: () => false,
    },
    isCloseable: false,
  };

  public ExtendSession() {
    this.isModalOpen = false;
    this.userSessionSubscription.unsubscribe();
  }
}
