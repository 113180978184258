import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
})
export class InputSearchComponent implements OnInit, OnDestroy {
  @Input() placeholder = 'Search by keyword';
  @Output() OnSearch = new EventEmitter<string>();
  subject: Subject<string> = new Subject();
  searchString = signal<string>('');
  subscriptions = new Subscription();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.subject.pipe(debounceTime(500)).subscribe((searchValue) => {
        this.searchString.set(searchValue);
        this.OnSearch.emit(searchValue);
        this.cdr.detectChanges();
      }),
    );
  }

  // trigger when user type in search box
  search(event: Event) {
    const val = (event.target as HTMLInputElement).value;
    this.subject.next(val);
  }

  // trigger when user click on search button
  applySearch() {
    this.OnSearch.emit(this.searchString());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
