import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, debounceTime, switchMap } from 'rxjs';
import { AutoCompleteDto } from 'src/app/models/AutoCompleteDto';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ContactRegistryService } from 'src/app/services/contact-registry.service';
import { RoutingSearchCriteriaDto } from 'src/app/models/RoutingSearchCriteriaDto';
import { UserService } from 'src/app/services/user.service';
import { SearchRequestDto } from '../../contact-registry/models/SearchRequestDto';
import { NgSelectConfig } from '@ng-select/ng-select';
export const routingNumberProp = 'routingNumber';

export type advanceSearchDto = {
  routingNumber?: FormControl<string | null>;
  DepartmentName?: FormControl<string | null>;
  email?: FormControl<string | null>;
  FirstName?: FormControl<string | null>;
  LastName?: FormControl<string | null>;
};

export type formInputDto = {
  type: string;
  inputType: string;
  name: any;
  label: string;
  placeholder: string;
  id: string;
  readonly: boolean;
};

export const clearSearch$ = new Subject();

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('ngSelect', { static: false }) public ngSelect:
    | ElementRef
    | undefined;
  @Input() autoCompleteList!: AutoCompleteDto[]; // getting autocomplete list from parent component
  @Input() autoCompletePlaceholder!: string; // getting autocomplete placeholder dynamicaly from parent component
  @Input() bindLabel!: string; // getting autocomplete label dynamicaly from parent component
  @Input() bindValue!: string; // getting autocomplete value dynamicaly from parent component
  @Output() OnSearchData = new EventEmitter<any>(); // emiting search inputs to parent component
  @Output() AutoCompleteData = new EventEmitter<any>();
  @Input() isMultipleEnabled = false;
  @Input() filterFiRouting = false;
  @Input() isSearchButtonVisible = true;
  @Input() isAdvanceSearchRequired = false;
  @Input() showDetailedRouting = false;
  @Input() primaryRouting: any;
  @Input() showRoutingNumberAndFiName = false;
  @Input() routingNumber?: any;
  @Input() readonly = false;
  @Output() selectedFiFromAutoComplete = new EventEmitter();
  isAutoCompleteActive = true;
  isAdvanceSearchActive = false;
  ifFilterSelected = false;
  searchCriteria: any[] = [];
  chipList: any[] = [];
  formData: any[] = [];
  routingNumbers: string[] = [];
  autocompleteTypeahead = new EventEmitter<string>();
  autocompleteTypeaheadSubject = new Subject<string>();
  routingSearchDto!: RoutingSearchCriteriaDto;
  searchRequest: SearchRequestDto = {};
  routingSearchCriteria: RoutingSearchCriteriaDto = {
    searchCriteria: '',
    showDetailedRouting: false,
  };

  searchForm: FormGroup = new FormGroup({});

  formInputList: formInputDto[] = [
    {
      type: 'input',
      inputType: 'text',
      name: 'DepartmentName',
      label: 'Department Name',
      placeholder: 'Enter Department Name',
      id: 'DepartmentName',
      readonly: false,
    },
    {
      type: 'input',
      inputType: 'email',
      name: 'email',
      label: 'Email Address',
      placeholder: 'Enter Email Address',
      id: 'email',
      readonly: false,
    },
    {
      type: 'input',
      inputType: 'text',
      name: 'FirstName',
      label: 'First Name',
      placeholder: 'Enter First Name',
      id: 'FirstName',
      readonly: false,
    },
    {
      type: 'input',
      inputType: 'text',
      name: 'LastName',
      label: 'Last Name',
      placeholder: 'Enter Last Name',
      id: 'LastName',
      readonly: false,
    },
  ];
  constructor(
    private utils: UtilitiesService,
    private contactRegistryService: ContactRegistryService,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    private ngSelectConfig: NgSelectConfig,
  ) {
    this.ngSelectConfig.notFoundText = 'No results found';
  }

  ngOnChanges(changes: any) {
    this.autoCompleteList = changes.autoCompleteList?.currentValue;
  }

  ngAfterViewChecked() {
    if (
      (this.ngSelect as any).element.querySelector('.ng-clear')?.innerHTML ===
      '×'
    ) {
      (this.ngSelect as any).element.querySelector('.ng-clear').innerHTML =
        '<img src="./assets/icons/trailing-icon.svg" alt="" />';
    }
  }

  async ngOnInit(): Promise<void> {
    this.routingSearchCriteria.showDetailedRouting = this.showDetailedRouting;
    this.CreateForm();
    if (this.filterFiRouting) {
      if (
        this.userService.isOdfiAdmin() ||
        this.userService.isOdfiUser() ||
        this.userService.isRdfiAdmin() ||
        this.userService.isRdfiUser()
      ) {
        this.routingSearchCriteria.searchCriteria =
          '' + this.userService.getPrimaryRoutingNumber();
        this.routingNumber = [this.primaryRouting?.routingNumber];
      } else {
        this.routingSearchCriteria.searchCriteria = '';
      }
      if (!this.userService.isNachaAdmin()) {
        await this.contactRegistryService
          .GetRoutingNumberList(this.routingSearchCriteria)
          .then((data: any) => {
            if (data != null) {
              this.autoCompleteList = data;
              if (
                this.userService.isOdfiAdmin() ||
                this.userService.isOdfiUser() ||
                this.userService.isRdfiAdmin() ||
                this.userService.isRdfiUser()
              ) {
                const routingNumber = this.autoCompleteList.find(
                  (el: any) =>
                    el.routingNumber ===
                    this.routingSearchCriteria.searchCriteria,
                );
                if (routingNumber) {
                  this.routingNumber = routingNumber.routingNumber;
                  const rtnsIndex = this.autoCompleteList.findIndex(
                    (el: any) =>
                      el.routingNumber === routingNumber?.routingNumber,
                  );
                  this.autoCompleteList[rtnsIndex]['disabled'] = true; // adding disabled property to the selected routing number to keep it disabled in the dropdown/preselected
                }
              }
              this.cdr.detectChanges();
            }
          });
      }
      this.AutoCompleteData.emit(this.autoCompleteList);
      this.cdr.detectChanges();
    }

    if (!this.userService.isNachaAdmin()) {
      this.isAdvanceSearchRequired = false;
    }
    clearSearch$.subscribe((p) => {
      this.ResetAutoCompleteAndAdvanceSearch();
    });
    this.autocompleteTypeaheadSubject
      .pipe(
        debounceTime(100),
        switchMap((event: string) => this.getRoutingNumberListAsync(event)),
      )
      .subscribe((res: any) => {
        res.subscribe((data: any) => {
          this.cdr.detectChanges();
          if (!this.filterFiRouting || this.userService.isNachaAdmin()) {
            this.autoCompleteList = data;
          } else {
            this.autoCompleteList = data;
          }
          this.AutoCompleteData.emit(this.autoCompleteList);
        });
      });
  }

  frameAutoCompleteList(data: AutoCompleteDto[]): AutoCompleteDto[] {
    const routingNumList: AutoCompleteDto[] = [];
    data.forEach(function (value: AutoCompleteDto) {
      value.secondaryRoutingNumbers?.forEach(function (
        secondaryNumber: string,
      ) {
        const routingNumber = {
          routingNumber: secondaryNumber,
          fiName: value.fiName,
          fiId: value.fiId,
          isOdfi: value.isOdfi,
          secondaryRoutingNumbers: value.secondaryRoutingNumbers,
        };
        routingNumList.push(routingNumber);
      });
    });

    return routingNumList;
  }

  getRoutingNumberListAsync(event: any) {
    this.routingSearchCriteria.searchCriteria = event;
    return this.contactRegistryService.GetRoutingNumberListAsync(
      this.routingSearchCriteria,
    );
  }

  SelectFilterItem(event: any) {
    this.searchCriteria = [];
    if (event) {
      if (this.routingNumber !== null && this.routingNumber !== undefined) {
        this.searchCriteria.push({
          filterType: 'routingNumber',
          filterValue: this.routingNumber,
          label: 'Routing Number',
        });
      }
      this.selectedFiFromAutoComplete.emit(event);
      this.OnSearchData.emit(this.searchCriteria);
      this.AutoCompleteData.emit(this.autoCompleteList);
      this.ifFilterSelected = event.routingNumber ? true : false;
    }
  }

  CreateForm() {
    for (const formModule of this.formInputList) {
      this.searchForm.addControl(formModule.name, new FormControl(null));
      this.searchCriteria = [];
      this.isAdvanceSearchActive = false;
      this.isAutoCompleteActive = true;
    }
  }

  ClearSearch() {
    this.searchForm.reset();
    this.formData = [];
    this.searchCriteria = [];
    this.chipList = [];
    this.isAdvanceSearchActive = false;
    this.isAutoCompleteActive = true;
    this.autoCompleteList = [];
    if (this.routingNumber !== null && this.routingNumber !== undefined) {
      this.searchCriteria.push({
        filterType: 'routingNumber',
        filterValue: this.routingNumber,
        label: 'Routing Number',
      });
      this.OnSearchData.emit(this.searchCriteria);
    } else {
      this.OnSearchData.emit(this.searchCriteria);
    }
  }

  async ClickAdvanceSearch() {
    this.searchCriteria = [];
    if (this.routingNumber !== null && this.routingNumber !== undefined) {
      this.searchCriteria.push({
        filterType: 'routingNumber',
        filterValue: this.routingNumber,
        label: 'Routing Number',
      });
    }
    this.formData = await this.utils.removeEmptyObjElement(
      this.searchForm.value,
    );
    if (Object.keys(this.formData).length === 0) return;
    for (const key in this.formData) {
      if (this.formData[key]) {
        const formInput: any = this.formInputList.find((el) => {
          return el.name === key;
        });
        this.searchCriteria.push({
          filterType: key,
          filterValue: this.formData[key],
          label: formInput.label,
        });
      }
    }
    this.OnSearchData.emit(this.searchCriteria);
    this.chipList = this.searchCriteria.filter(
      (p) => p.filterType !== 'routingNumber',
    );
    this.isAdvanceSearchActive = false;
  }

  RemoveChips(chipsKey: any) {
    const index = this.searchCriteria.findIndex(
      (p) => p.filterType === chipsKey,
    );
    this.searchCriteria.splice(index, 1);
    this.chipList = this.searchCriteria.filter(
      (p) => p.filterType !== 'routingNumber',
    );
    this.searchForm.patchValue({ [chipsKey]: null });
    if (!this.searchCriteria.length) {
      this.isAutoCompleteActive = true;
      this.isAdvanceSearchActive = false;
      this.formData = [];
    }
    this.OnSearchData.emit(this.searchCriteria);
  }

  ResetAutoCompleteAndAdvanceSearch() {
    this.searchForm.reset();
    this.searchCriteria = [];
    this.routingNumber = null;
    this.formData = [];
    this.ifFilterSelected = false;
    this.OnSearchData.emit(this.formData);
  }

  ClickSearch() {
    this.searchCriteria = [];
    if (this.routingNumber !== null && this.routingNumber !== undefined) {
      this.searchCriteria.push({
        filterType: 'routingNumber',
        filterValue: this.routingNumber,
        label: 'Routing Number',
      });
    }

    if (this.searchCriteria) {
      this.searchRequest.filterCategories = this.searchCriteria;
      const routingFilter: any = this.searchRequest.filterCategories
        ?.filter((x) => x.filterType === routingNumberProp)
        .map((x) => x.filterValue)[0];

      if (!routingFilter || routingFilter.length == 0) {
        if (this.userService.isNachaAdmin()) {
          this.autoCompleteList = [];
        }
        this.routingNumbers = routingFilter;
        this.cdr.detectChanges();
      } else {
        if (this.isMultipleEnabled && this.filterFiRouting) {
          const routingNumberPrevCount = this.routingNumbers.length;
          this.routingNumbers = routingFilter;
          if (
            this.routingNumbers !== null &&
            this.routingNumbers !== undefined &&
            this.routingNumbers?.length == 1 &&
            this.routingNumbers?.length > routingNumberPrevCount &&
            this.userService.isNachaAdmin()
          ) {
            const routingNumberDetails = this.autoCompleteList.filter(
              (data) => data.routingNumber == this.routingNumbers[0],
            );
            const det: any =
              routingNumberDetails[0]?.secondaryRoutingNumbers?.map((rtn) => {
                return {
                  routingNumber: String(rtn),
                  fiName: String(routingNumberDetails.map((x) => x.fiName)),
                  fiId: Number(routingNumberDetails.map((x) => x.fiId)),
                  isOdfi: routingNumberDetails.map((x) => x.isOdfi),
                  secondaryRoutingNumbers: routingNumberDetails.map(
                    (x) => x.secondaryRoutingNumbers,
                  ),
                };
              });
            this.autoCompleteList = det;
            this.AutoCompleteData.emit(this.autoCompleteList);
          }
        }
      }
    }

    this.OnSearchData.emit(this.searchCriteria);
  }

  SearchContactRegistry(event: any) {
    if (!isNaN(Number(event.term))) {
      this.bindLabel = 'routingNumber';
    } else {
      this.bindLabel = 'fiName';
    }
    if (event.term.length > 2) {
      if (
        !this.filterFiRouting ||
        this.routingNumber === null ||
        this.routingNumber === undefined ||
        this.routingNumber?.length == 0
      ) {
        if (this.userService.isNachaAdmin()) this.autoCompleteList = [];
        this.autocompleteTypeaheadSubject.next(event.term);
      }
    }
  }

  ClearSelection() {
    this.routingNumber = null;
  }
}
