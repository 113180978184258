import { Component, Input, OnChanges } from '@angular/core';

export type accordianDto = {
  accordianSubheading: any;
  name?: string;
  collapse?: boolean;
  id?: number;
  class?: string;
  isSecondaryDisabled?: boolean;
  isHeaderClassRequired?: boolean;
  primaryContactList: any[];
  secondaryContactList: any[];
  expandDisabled?: boolean;
};

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnChanges {
  @Input() accordianInfo: accordianDto = {} as accordianDto;
  @Input() isSeperatorRequired = true;
  className = 'hide';

  ngOnChanges(changes: any) {
    this.accordianInfo = changes.accordianInfo.currentValue;
  }

  Toggle() {
    this.className = this.className === 'hide' ? 'show' : 'hide';
  }
}
