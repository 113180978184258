function loadDocuSign(integrationKey, signingUrl, eventCallback) {
  window.DocuSign.loadDocuSign(integrationKey)
    .then((docusign) => {
      const signing = docusign.signing({
        url: signingUrl,
        displayFormat: 'default', // 'focused' is the minimal view
        style: {
          /** High-level variables that mirror our existing branding APIs. Reusing the branding name here for familiarity. */
          branding: {
            primaryButton: {
              /** Background color of primary button */
              backgroundColor: '#2f6cf6',
              /** Text color of primary button */
              color: '#fff',
            },
          },

          /** High-level components we allow specific overrides for */
          signingNavigationButton: {
            position: 'bottom-center',
          },
        },
      });

      signing.on('ready', (event) => {
        eventCallback && eventCallback('ready', event);
      });

      signing.on('sessionEnd', (event) => {
        /** The event here denotes what caused the sessionEnd to trigger, such as signing_complete, ttl_expired etc../ **/
        eventCallback && eventCallback('sessionEnd', event);
      });

      signing.mount('#agreement');
    })
    .catch((ex) => {
      console.error('Error loading DocuSign', ex);
    });
}
