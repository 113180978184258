import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { accordianDto } from '../../common-components/accordion/accordion.component';
import { GeneralService } from 'src/app/services/general.service';
import { ActivatedRoute, Router } from '@angular/router';

export type InstructionDisplayDto = {
  fieldName: string;
  rules: string[];
  examples?: string[];
  accordianInfo: accordianDto;
};

@Component({
  selector: 'upload-instructions',
  templateUrl: './upload-instructions.component.html',
  styleUrls: ['./upload-instructions.component.scss'],
})
export class UploadInstructionsComponent implements OnInit {
  moduleName: string | null = '';
  pageTitle = '';
  uploadInstructionsDisplay: InstructionDisplayDto[] = [];

  constructor(
    private generalService: GeneralService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.moduleName = this.route.snapshot.queryParamMap.get('modulename');
    await this.generalService
      .GetInstructionsByModule(this.moduleName)
      .then((data) => {
        if (data != null) {
          this.pageTitle = data.pageTitle;
          this.uploadInstructionsDisplay = data.pageRules.map(
            function (instructions, index) {
              return {
                fieldName: instructions.fieldTitle,
                rules: instructions.fieldRules,
                examples: instructions.fieldExamples,
                accordianInfo: {
                  id: index,
                  name: instructions.fieldTitle,
                  collapse: true,
                  class: 'hide',
                  accordianSubheading: undefined,
                  primaryContactList: [],
                  secondaryContactList: [],
                },
              };
            },
          );
        }
        this.cdr.detectChanges();
      });
  }
}
