import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  // get active wizard index
  activeWiWizardIndex!: number;
  private activeWiWizardIndexInitial: BehaviorSubject<any> =
    new BehaviorSubject<any>(this.activeWiWizardIndex);
  activeWiWizardIndexData = this.activeWiWizardIndexInitial.asObservable();
  isSubmitButtonEnabled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isCancelButtonDisabled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  GetActiveWiWizardIndex(data: any) {
    this.activeWiWizardIndexInitial.next(data);
    this.activeWiWizardIndex = data;
    return data;
  }

  isNextButtonDisabled = true;
  private isNextButtonDisabledInitial: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(this.isNextButtonDisabled);
  isNextButtonDisabledData = this.isNextButtonDisabledInitial.asObservable();

  GetNextButtonDisabled(data: any) {
    this.activeWiWizardIndexInitial.next(data);
    this.isNextButtonDisabled = data;
    return data;
  }

  ResetState() {
    this.GetActiveWiWizardIndex(undefined);
    this.GetNextButtonDisabled(false);
    this.isSubmitButtonEnabled.next(false);
    this.isCancelButtonDisabled.next(false);
  }
}
