import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ColumnConfigurtionDto } from 'src/app/models/ColumnConfigurtionDto';

export const clearFilter$ = new Subject();
@Component({
  selector: 'columns-to-display',
  templateUrl: './columns-to-display.component.html',
  styleUrls: ['./columns-to-display.component.scss'],
})
export class ColumnsToDisplayComponent implements OnInit {
  displayContainer = false;
  @Input() inputColumns?: ColumnConfigurtionDto[];
  @Input() minColumnLimitation?: number;
  @Input() maxColumnLimitation?: number;
  @Output() emitColumnsData = new EventEmitter<ColumnConfigurtionDto[]>();
  outputColumns: ColumnConfigurtionDto[] = [];

  ngOnInit(): void {
    this.LoadColumns();
  }
  LoadColumns() {
    if (this.inputColumns != null) {
      //todo
    }
  }

  onCheckboxChange(column: ColumnConfigurtionDto, event: any) {
    const userWidth = window?.innerWidth;
    let checkLimit = 0;
    if (userWidth >= 1920) {
      checkLimit = this.maxColumnLimitation!;
    } else {
      checkLimit = this.minColumnLimitation!;
    }

    const count = this.inputColumns!.filter((arr) => {
      if (arr.isChecked) {
        return true;
      }
      return false;
    }).length;
    if (event.target.checked) {
      if (count < checkLimit) {
        this.inputColumns!.forEach((element: any) => {
          if (element.keyName === column.keyName) {
            element.isChecked = true;
          }
        });
      } else {
        event.target.checked = false;
      }
    } else {
      this.inputColumns!.forEach((element: any) => {
        if (element.keyName === column.keyName) {
          element.isChecked = false;
        }
      });
    }
  }

  OkClick() {
    this.emitColumnsData.emit(this.inputColumns);
  }
}
