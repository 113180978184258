import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../modules/common-components/spinner/service/spinner.service';
import { TokenStorageService } from '../infrastructure/authentication/token-storage.service';

@Component({
  selector: 'logout',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private tokenstorage: TokenStorageService,
    private spinner: SpinnerService,
  ) {}

  ngOnInit() {
    this.spinner.Show();
    this.tokenstorage.ClearSession();
  }
}
