import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { UserService } from '../services/user.service';
import { OptInProgramService } from '../services/opt-in-program.service';
import { CustomHttpClientService } from '../infrastructure/services/custom-http-client.service';
import {
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { ModalService } from '../services/modal.service';
import { GeneralService } from 'src/app/services/general.service';
import { ConditionalMenuDto } from '../models/conditionalMenuDto';
import { TokenStorageService } from '../infrastructure/authentication/token-storage.service';
import { AuthenticationService } from '../infrastructure/authentication/authentication.service';
import { Constants } from 'src/app/models/Constants';
import { ConfigSettingsDto } from '../models/ConfigSettingsDto';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
})
export class AppSidebarComponent implements AfterViewInit, OnInit, OnDestroy {
  public isSideBarExpanded = false;
  public selectedSubmenuIndex: HTMLElement | null = null;
  private previousEl: HTMLElement | null = null;
  public showManagementSection = true;
  isTpsAllowed = true;
  isTodAllowed = true;
  isDaAllowed = true;
  isNachaUser = false;
  subscription: Subscription = new Subscription();
  isbIcon = './assets/icons/default_isb.svg';
  tabConfigList: ConfigSettingsDto[] = [];

  constructor(
    private readonly userService: UserService,
    private router: Router,
    private readonly optInProgramService: OptInProgramService,
    private readonly http: CustomHttpClientService,
    private modalService: ModalService,
    private generalService: GeneralService,
    private readonly cdr: ChangeDetectorRef,
    private tokenStorageService: TokenStorageService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.isNachaUser = this.userService.isNachaUser();
    if (this.userService.isOdfi() || this.userService.isRdfi()) {
      this.getConditionalMenu();
      this.subscription.add(
        this.generalService.conditionalMenuItems.subscribe(
          (data: ConditionalMenuDto) => {
            this.isTpsAllowed = data.isTpsAllowed;
            this.isTodAllowed = data.isTodAllowed;
            this.isDaAllowed = data.isDaAllowed;
            this.cdr.detectChanges();
            this.handlePathConditions(data);
          },
        ),
      );
    }
    this.getTabConfigDetails();
  }

  public async getTabConfigDetails() {
    const params = 'ShowISBTab'; //We can pass multiple keys separated by comma
    this.generalService.GetConfigsAsync(params).then((res) => {
      this.tabConfigList = res;
      this.incidentSharingBoardAccess();
      this.cdr.detectChanges();
    });
  }

  public getConditionalMenu() {
    this.fetchConditionalMenu().then((res: ConditionalMenuDto) => {
      this.updateConditionalMenuItems(res);
      this.handlePathConditions(res);
      this.cdr.detectChanges();
    });
  }

  private fetchConditionalMenu(): Promise<ConditionalMenuDto> {
    return this.generalService.getConditionalMenu();
  }

  private updateConditionalMenuItems(res: ConditionalMenuDto) {
    this.generalService.conditionalMenuItems.next(res);
  }

  private handlePathConditions(res: ConditionalMenuDto) {
    const path = window.location.pathname;
    if (
      (path === '/tps/management' && !res.isTpsAllowed) ||
      (path === '/tps/bulk-history' && !res.isTpsAllowed) ||
      (path === '/tod/management' && !res.isTodAllowed) ||
      (path === '/direct-access/management' && !res.isDaAllowed)
    ) {
      this.router.navigate(['/dashboard']);
    }
  }

  expandMenu() {
    this.isSideBarExpanded = !this.isSideBarExpanded;
    this.HideContactModal();
  }

  HideContactModal() {
    this.modalService.isModalActive = false;
  }

  ngAfterViewInit() {
    const hasChildren =
      document.querySelectorAll('#management a.sub-menu')?.length > 0;

    if (!hasChildren && !this.userService.isNacha()) {
      this.showManagementSection = false;
      this.cdr.detectChanges();
    }
  }

  public menuFocus(event: any, itemEl: HTMLElement) {
    this.previousEl?.classList?.remove('active');
    const aElement = event.target.closest('a');
    aElement.classList.add('active');
    this.previousEl = aElement;
    this.selectedSubmenuIndex = itemEl;
    this.isSideBarExpanded = false;
    this.HideContactModal();
    if (
      itemEl.querySelector('span')?.textContent === 'Incident Sharing Board'
    ) {
      this.isbIcon = './assets/icons/selected_isb.svg';
    } else {
      this.isbIcon = './assets/icons/default_isb.svg';
    }
  }

  public showSecureExchangeSection(): boolean {
    return (
      this.userService.isNacha() ||
      this.userService.isOdfi() ||
      this.userService.isRdfi()
    );
  }

  public secureTemplateManagementAccess(): boolean {
    return this.userService.isNacha();
  }

  public secureDocumentAccess(): boolean {
    return this.userService.isOdfi() || this.userService.isRdfi();
  }

  public secureExchangeAuditLogAccess(): boolean {
    return this.userService.isNacha();
  }

  public achContactRegistryAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      this.userService.isOdfiAdmin() ||
      this.userService.isOdfiUser() ||
      this.userService.isRdfiAdmin() ||
      this.userService.isRdfiUser()
    );
  }

  public manageContactAccess(): boolean {
    return !this.userService.isNachaUser();
  }

  public incidentSharingBoardAccess(): boolean {
    const isbTabConfig = this.tabConfigList.filter(
      (x) => x.key === 'ShowISBTab',
    );

    if (
      isbTabConfig &&
      isbTabConfig.length > 0 &&
      isbTabConfig[0].value === 'true'
    ) {
      return (
        this.userService.isNacha() ||
        this.userService.isOdfi() ||
        this.userService.isRdfi()
      );
    } else {
      return false;
    }
  }

  public achContactRegistrySearchAccess(): boolean {
    return (
      this.userService.isPaAdmin() ||
      this.userService.isPaUser() ||
      this.userService.isAchOperatorAdmin() ||
      this.userService.isAchOperatorUser()
    );
  }

  public thirdPartySenderAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      ((this.userService.isOdfiAdmin() || this.userService.isOdfiUser()) &&
        this.isTpsAllowed)
    );
  }

  public daManagementAccess(): boolean {
    return (
      this.userService.isNacha() ||
      ((this.userService.isOdfiAdmin() || this.userService.isOdfiUser()) &&
        this.isDaAllowed)
    );
  }

  public todAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      ((this.userService.isOdfiAdmin() || this.userService.isOdfiUser()) &&
        this.isTodAllowed) ||
      ((this.userService.isRdfiAdmin() || this.userService.isRdfiUser()) &&
        this.isTodAllowed) ||
      this.userService.isTpspAdmin() ||
      this.userService.isTpspUser() ||
      this.userService.isTpsAdmin() ||
      this.userService.isTpsUser()
    );
  }

  public userManagementAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      this.userService.isOdfiAdmin() ||
      this.userService.isRdfiAdmin() ||
      this.userService.isPaAdmin() ||
      this.userService.isTpsAdmin() ||
      this.userService.isTpspAdmin() ||
      this.userService.isAchOperatorAdmin()
    );
  }

  public paManagementAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      this.userService.isPaAdmin() ||
      this.userService.isPaUser()
    );
  }

  public achOperatorManagementAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      this.userService.isAchOperatorAdmin() ||
      this.userService.isAchOperatorUser()
    );
  }

  public tpspTpsManagementAccess(): boolean {
    return this.userService.isNachaAdmin() || this.userService.isNachaUser();
  }

  public tpsManagementAccess(): boolean {
    return this.userService.isTpsAdmin() || this.userService.isTpsUser();
  }

  public tpspManagementAccess(): boolean {
    return this.userService.isTpspAdmin() || this.userService.isTpspUser();
  }

  public odfiManagementAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      this.userService.isOdfiAdmin() ||
      this.userService.isOdfiUser()
    );
  }

  public rdfiManagementAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      this.userService.isRdfiAdmin() ||
      this.userService.isRdfiUser()
    );
  }

  public accuityManagementAccess(): boolean {
    return this.userService.isNachaAdmin();
  }

  public reportsManagementAccess(): boolean {
    return this.userService.isNachaAdmin() || this.userService.isNachaUser();
  }

  public pendingApprovalsAccess(): boolean {
    return this.userService.isNachaAdmin() || this.userService.isNachaUser();
  }

  public registrationSummaryAccess(): boolean {
    return this.userService.isNachaAdmin() || this.userService.isNachaUser();
  }

  public registrationSummaryDocumentAccess(): boolean {
    return (
      this.userService.isOdfiAdmin() ||
      this.userService.isOdfiUser() ||
      this.userService.isRdfiAdmin() ||
      this.userService.isRdfiUser()
    );
  }

  public auditLogAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      this.userService.isFiAdmin()
    );
  }

  public optInProgramAccess(): boolean {
    return this.userService.isNachaAdmin() || this.userService.isNachaUser();
  }

  public optInProgramListAccess(): boolean {
    return (
      this.userService.isOdfiAdmin() ||
      this.userService.isOdfiUser() ||
      this.userService.isRdfiAdmin() ||
      this.userService.isRdfiUser() ||
      this.userService.isPaAdmin() ||
      this.userService.isPaUser()
    );
  }

  public suspendedOriginatorAccess(): boolean {
    return (
      this.userService.isNachaAdmin() ||
      this.userService.isNachaUser() ||
      this.userService.isOdfiAdmin() ||
      this.userService.isOdfiUser() ||
      this.userService.isRdfiAdmin() ||
      this.userService.isRdfiUser() ||
      this.userService.isPaAdmin() ||
      this.userService.isPaUser() ||
      this.userService.isAchOperatorAdmin() ||
      this.userService.isAchOperatorUser()
    );
  }

  OptInProgramUploadAccess() {
    return !this.userService.isNachaUser();
  }

  public portalSettingsAccess(): boolean {
    return this.userService.isNachaAdmin();
  }

  public ShowSubmenu(itemEl: HTMLElement) {
    const isExpanded = itemEl.classList.contains('showMenu');

    const allSubMenuItems = document.querySelectorAll('.navbar-nav li');
    allSubMenuItems.forEach((el) => {
      if (el !== itemEl) {
        el.classList.remove('showMenu');
      }
    });

    itemEl.classList.toggle('showMenu', !isExpanded);
    this.isSideBarExpanded = true;
    this.HideContactModal();
  }

  public odfiManagementRedirectTo(): string {
    if (this.userService.isOdfi()) {
      const rerr = '/management/fi/edit';
      return rerr;
    } else {
      return '/management/odfi';
    }
  }

  public rdfiManagementRedirectTo(): string {
    if (this.userService.isRdfi()) {
      return '/management/fi/edit';
    } else {
      return '/management/rdfi';
    }
  }

  public NavigateToPowerBiDashboard(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.http
      .get('api/config-api/powerbi-report-url')
      .subscribe((response: any) => {
        const decodedUrl = decodeURIComponent(response.url);
        window.open(decodedUrl, '_blank');
      });
  }

  public NavigateToSecureEmail(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.http
      .get('api/auth/secure-email/get-redirect-url')
      .subscribe((response: any) => {
        window.open(response.url, '_blank');
      });
  }

  public async DownloadOptInDocumentFile() {
    await this.optInProgramService.DownloadFile();
    this.HideContactModal();
  }

  GotoTpspTpsManagement(fiType: string) {
    this.router.navigate(['/management/tpsp/' + this.userService.getFiId()]);
    const navigationExtras: NavigationExtras = {
      state: {
        fiId: this.userService.getFiId(),
        fiType: fiType,
        activeTpsp: true,
      },
      replaceUrl: true,
    };
    this.HideContactModal();
    this.router.navigate(['/management/edit'], navigationExtras);
  }

  GotoAchOperatorManagement() {
    if (this.userService.isNacha()) {
      this.router.navigate(['/management/ach-operator']);
    } else if (this.userService.isAchOperator()) {
      this.router.navigate(['/management/ach-operator/view']);
    }
    this.HideContactModal();
  }

  GotoPAManagement() {
    if (this.userService.isNacha()) {
      this.router.navigate(['/management/pa']);
    } else if (this.userService.isPaAdmin()) {
      this.router.navigate(['/management/pa/edit']);
    } else if (this.userService.isPaUser()) {
      this.router.navigate(['/management/pa/view']);
    }
    this.HideContactModal();
  }

  GotoSecureTemplateManagement() {
    this.router.navigate(['/secure-exchange/management']);
    this.HideContactModal();
  }

  GotoSecureExchangeAuditLogs() {
    this.router.navigate(['/secure-exchange/activity']);
    this.HideContactModal();
  }

  GotoSecureDocument() {
    this.NavigateWithReAuthentication(
      Constants.IsReAuthenticatedClaim,
      '/secure-exchange/document',
      'Exchange',
      'Secure Exchange',
    );
  }

  GotoAdministrationSettingsPage() {
    this.NavigateWithReAuthentication(
      Constants.IsReAuthenticatedClaim,
      '/portal-administrative-settings',
      'Settings',
      'Portal Administrative Settings',
    );
  }

  NavigateWithReAuthentication(
    claim: string,
    route: string,
    windowTitle: string,
    headerTitle: string,
  ) {
    if (!this.tokenStorageService.ContainsClaim(claim)) {
      this.authService
        .ReAuthenticate(
          this.userService.getUserName()!,
          route,
          windowTitle,
          headerTitle,
        )
        .subscribe((response) => {
          this.HideContactModal();
        });
    }
    {
      this.router.navigate([route]);
      this.HideContactModal();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
