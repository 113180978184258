import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'no-result-found',
  templateUrl: './no-result-found.component.html',
  styleUrls: ['./no-result-found.component.scss'],
})
export class NoResultFoundComponent implements OnChanges {
  @Input() message = 'No records found';

  ngOnChanges(changes: any) {
    this.message = changes.message?.currentValue
      ? changes.message?.currentValue
      : 'No records found';
  }
}
