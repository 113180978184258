<div
  class="nav-container d-flex shadow-sm"
  [ngClass]="isStickyHeader ? 'fixed-header' : ''"
>
  <div class="d-flex justify-content-between align-items-center w100">
    <div class="logo-box">
      <a href="https://www.nacha.org/" target="_blank"
        ><img src="assets\icons\nacha-logo.svg" width="150px"
      /></a>
    </div>
    <div
      class="nav-action-items-container d-flex justify-content-end align-items-center"
    >
      <div class="support-container d-flex align-items-center">
        <div class="support-txt">Support:</div>
        <div class="support-contact fw-500">(703) 349-4556</div>
      </div>
      <div class="nav-icon-container">
        <a [routerLink]="['/dashboard']">
          <img src="assets\icons\home.svg" />
        </a>
      </div>
      <!-- <div class="nav-icon-container">
          <a [routerLink]="['/']">
            <img src="assets\icons\notifications.svg" />
          </a>
        </div> -->
      <div class="d-inline-flex c-p" (clickOutside)="CloseSupportDropdown()">
        <div class="nav-icon-container supportDropdown">
          <a
            ><img
              id="help"
              [src]="
                isHelpDropdownOpen
                  ? './assets/icons/header-help.svg'
                  : './assets/icons/header-help-lite.svg'
              "
              (click)="isHelpDropdownOpen = !isHelpDropdownOpen"
          /></a>
          <div
            class="support-dropdown-main-container"
            *ngIf="isHelpDropdownOpen"
          >
            <div class="d-flex flex-column">
              <div
                class="support-list-container"
                *ngFor="let s of supportDropdownList; let i = index"
                (click)="s.hasDropdown ? '' : GoToPortalInstructions(s.url)"
              >
                <div
                  class="d-flex justify-content-between align-items-center parentdrpdn"
                  (click)="ToggleChildDropdown(i)"
                  [ngClass]="s.isDropdownActive ? 'activeDropdown' : ''"
                >
                  <div class="list-name">
                    <img
                      *ngIf="i === 0 && s.isDropdownActive; else other_icon"
                      class="icon-suppor"
                      src="{{ './assets/icons/' + s.activeIcon + '.svg' }}"
                      alt=""
                    />
                    <ng-template #other_icon>
                      <img
                        class="icon-suppor"
                        src="{{ './assets/icons/' + s.icon + '.svg' }}"
                        alt=""
                      />
                    </ng-template>
                    <span class="ins-txt">{{ s.label }}</span>
                  </div>
                  <img
                    *ngIf="s?.hasDropdown"
                    class="icon-suppor"
                    [src]="
                      !s.isDropdownActive
                        ? './assets/icons/expand_more.svg'
                        : './assets/icons/expand_more-lite.svg'
                    "
                    alt=""
                  />
                </div>
                <div
                  class="d-flex flex-column dropdown-child-main-cont"
                  *ngIf="s?.hasDropdown && s?.isDropdownActive"
                >
                  <div
                    class="dropdown-child-container"
                    *ngFor="let sd of s.dropdownList"
                    (click)="GoToPortalInstructions(sd.url)"
                  >
                    {{ sd.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line-h"></div>
      <div
        class="dropdown d-inline-flex"
        (clickOutside)="isUserDropdownOpen = false"
      >
        <div
          class="user-box-container d-inline-flex justify-content-center align-items-center"
          (click)="this.isUserDropdownOpen = !this.isUserDropdownOpen"
        >
          <span *ngIf="fullName">{{ shortName | uppercase }}</span>
        </div>
        <div
          class="dropdown-content"
          [ngClass]="isUserDropdownOpen ? 'show' : 'hide'"
        >
          <a
            class="d-flex"
            [routerLink]="'profile'"
            (click)="this.isUserDropdownOpen = !this.isUserDropdownOpen"
          >
            <img
              src="./assets/icons/account-circle.svg"
              class="prof-icon"
              alt=""
            />
            <span>Profile</span>
          </a>
          <a class="d-flex" (click)="ChangePassword()">
            <img src="./assets/icons/settings.svg" class="prof-icon" alt="" />
            <span>Change Password</span>
          </a>
          <!-- <a class="d-flex" [routerLink]="'/'">
                <img src="./assets/icons/help.svg" alt="">
                <span>Support</span>
            </a> -->
          <a class="d-flex" (click)="Logout()">
            <img src="./assets/icons/logout.svg" class="prof-icon" alt="" />
            <span>Log Out</span>
          </a>
        </div>
        <div
          class="user-info-container d-flex flex-column justify-content-center"
        >
          <div class="user-email-info">{{ fullName }}</div>
          <div class="user-type-info fw-700">{{ userRole }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
