<form [formGroup]="searchForm">
  <div
    class="search-main-container"
    (clickOutside)="isAdvanceSearchActive = false"
  >
    <div>
      <div class="input-group">
        <div class="search-container d-flex">
          <div class="input-group-text">
            <img class="c-p" src="./assets/icons/search.svg" alt="" />
          </div>
          <ng-select
            #ngSelect
            [items]="autoCompleteList"
            [bindLabel]="bindLabel"
            [bindValue]="bindValue"
            [multiple]="isMultipleEnabled"
            [closeOnSelect]="true"
            [searchable]="!readonly"
            [clearable]="ifFilterSelected && !readonly"
            [placeholder]="autoCompletePlaceholder"
            [(ngModel)]="routingNumber"
            [ngModelOptions]="{ standalone: true }"
            (change)="SelectFilterItem($event)"
            (search)="SearchContactRegistry($event)"
            (clear)="ResetAutoCompleteAndAdvanceSearch()"
            [typeahead]="autocompleteTypeahead"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
              let-index="index"
              let-search="searchTerm"
            >
              <div
                *ngIf="bindLabel === 'routingNumber'"
                class="ac-option d-flex align-items-center"
              >
                <span
                  class="w-100"
                  [innerHTML]="item.routingNumber | highlight: search"
                ></span>
                <span>{{ item.fiName }}</span>
              </div>
              <div
                *ngIf="bindLabel === 'fiName'"
                class="ac-option d-flex align-items-center"
              >
                <span
                  class="w-300"
                  [innerHTML]="item.fiName | highlight: search"
                ></span>
                <span>{{ item.routingNumber }}</span>
              </div>
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span
                class="ng-value-icon left"
                (click)="clear(item)"
                aria-hidden="true"
                ><img src="./assets/icons/trailing-icon.svg" alt=""
              /></span>
              <span
                class="ng-value-label"
                *ngIf="!showRoutingNumberAndFiName"
                >{{ item.routingNumber }}</span
              >
              <span class="ng-value-label" *ngIf="showRoutingNumberAndFiName"
                >{{ item.routingNumber }} - {{ item.fiName }}</span
              >
            </ng-template>
          </ng-select>
          <div
            class="input-group-text"
            *ngIf="isAdvanceSearchRequired"
            (click)="isAdvanceSearchActive = !isAdvanceSearchActive"
          >
            <img
              class="c-p advance-search-button"
              src="./assets/icons/tune.svg"
              alt=""
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Advanced Search"
            />
          </div>
          <!-- advance search block start-->
          <div class="advance-search-container" *ngIf="isAdvanceSearchActive">
            <div
              class="advance-search-items d-flex flex-wrap justify-content-between"
            >
              <div class="input-group" *ngFor="let field of formInputList">
                <ng-container *ngIf="field.type === 'input'">
                  <div class="d-flex flex-column">
                    <label class="fw-700 advance-search-label" for="field.id">{{
                      field.label
                    }}</label>
                    <input
                      [type]="field.inputType"
                      class="form-control search-input-control"
                      [id]="field.id"
                      [placeholder]="field.placeholder"
                      [formControlName]="field.name"
                      [readonly]="field.readonly"
                    />
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="me-2">
                <button-component
                  [buttonClass]="'button button-light'"
                  Text="Clear"
                  [isLeftImg]="false"
                  [isRightImg]="false"
                  [isButtonDisabled]="false"
                  (OnClick)="ClearSearch()"
                ></button-component>
              </div>
              <div class="me-2">
                <button-component
                  [buttonClass]="'button button-dark'"
                  Text="Search"
                  [isButtonDisabled]="false"
                  (OnClick)="ClickAdvanceSearch()"
                ></button-component>
              </div>
            </div>
          </div>
          <!-- advance search block end-->
          <button-component
            *ngIf="this.isSearchButtonVisible"
            [buttonClass]="'button button-dark no-radius'"
            Text="Search"
            [isLeftImg]="false"
            [isRightImg]="false"
            [isButtonDisabled]="false"
            (OnClick)="ClickSearch()"
          ></button-component>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div *ngIf="chipList.length">
      <div
        class="advance-search-chips-container d-flex align-items-center flex-wrap"
      >
        <div
          class="chips-container d-flex align-items-center"
          *ngFor="let chip of chipList; let i = index"
        >
          <span>{{ chip.label }}: {{ chip.filterValue }}</span>
          <img
            class="c-p"
            src="./assets/icons/close.png"
            alt=""
            (click)="RemoveChips(chip.filterType)"
          />
        </div>
      </div>
    </div>
  </div>
</form>
