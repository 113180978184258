import { Component, Input, OnInit, OnChanges } from '@angular/core';

export interface ContextMenuItem {
  label: string;
  icon: string;
  title: string;
  action: (event: any) => void;
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit, OnChanges {
  isDropdownOpen = false;
  menuAlignment = '';
  @Input() align = '';
  @Input() contextMenuItems: ContextMenuItem[] = [];
  @Input() icon = 'more-vert.svg';

  ngOnChanges(changes: any) {
    this.icon = changes.icon?.currentValue
      ? changes.icon?.currentValue
      : 'more-vert.svg';
  }

  ngOnInit(): void {
    this.menuAlignment = this.align;
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
