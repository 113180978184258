import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { GoogleService } from './services/google.service';
import { DOCUMENT } from '@angular/common';
import { AddressDetails } from './models/AddressDetails';

declare let google: any;
@Component({
  selector: 'google-address',
  templateUrl: './google-address.component.html',
  styleUrls: ['./google-address.component.scss'],
})
export class GoogleAddressComponent
  implements AfterViewInit, OnInit, OnChanges, OnDestroy
{
  constructor(
    private readonly googleService: GoogleService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input() addressType!: string;
  @Input() placeholder!: string;
  @Input() readonly = false;
  @Input() countries!: string[];
  @Output() setAddress: EventEmitter<AddressDetails> = new EventEmitter();
  @Output() allowManualAddress: EventEmitter<boolean> = new EventEmitter();
  @Output() ResetAddressValue = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
  autocompleteInput: string | undefined;
  @Input() streetAddress: string | null = null;
  housenumber: any | undefined;
  streetname: any | undefined;
  city: any | undefined;
  state: any | undefined;
  zip: any | undefined;
  country: any | undefined;
  addressDetails: AddressDetails = {} as AddressDetails;
  isValidAddress = true;
  isDropdownOpen = false;

  queryWait: boolean | undefined;
  private readonly url: string =
    'https://maps.googleapis.com/maps/api/js?libraries=places&callback=Function.prototype&key=';

  ngOnChanges(changes: any) {
    this.autocompleteInput =
      changes.streetAddress?.currentValue ?? this.streetAddress;
    if (
      changes.readonly?.currentValue === true ||
      changes.readonly?.currentValue === false
    ) {
      this.readonly = changes.readonly.currentValue;
    }
  }

  ngOnInit() {
    this.googleService.address.subscribe((res: string) => {
      if (res) {
        this.autocompleteInput = res;
        this.streetAddress = res;
      }
    });
  }

  ngAfterViewInit() {
    this.googleService.GetGoogleApiURL().subscribe((res: any) => {
      this.loadGoogleAddress(this.url + res.apiKey).then((data) =>
        this.getPlaceAutocomplete(),
      );
    });

    this.addresstext.nativeElement.addEventListener('input', (event: any) => {
      if (
        event.target.value.length === 0 ||
        event.target.value.trim().length <= 2
      ) {
        this.isValidAddress = true;
        this.cdr.detectChanges();
        return;
      }
      // Check if the autocomplete dropdown is visible
      const dropdown = document.querySelector('.pac-container') as HTMLElement;
      if (dropdown && dropdown.children.length > 0) {
        this.isValidAddress = true;
      } else if (event.target.value.trim().length > 2) {
        // Check if the address is having atleast 3 characters
        this.isValidAddress = false;
      }
      this.cdr.detectChanges();
    });
  }

  private loadGoogleAddress(url: string) {
    return new Promise((resolve, reject) => {
      const existingScript = this.document.querySelector(
        `script[src="${url}"]`,
      );
      if (existingScript) {
        if ((existingScript as HTMLScriptElement).onload) {
          resolve(true);
        } else {
          existingScript.addEventListener('load', () => resolve(true));
          existingScript.addEventListener('error', () => reject(false));
        }
        return;
      }

      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.text = ``;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer2.appendChild(this.document.body, script);
    });
  }

  private getPlaceAutocomplete() {
    let autocomplete: any;
    if (this.countries === undefined || this.countries.length === 0) {
      autocomplete = new google.maps.places.Autocomplete(
        this.addresstext.nativeElement,
        {
          types: [this.addressType], // 'establishment' / 'address' / 'geocode'
        },
      );
    } else {
      autocomplete = new google.maps.places.Autocomplete(
        this.addresstext.nativeElement,
        {
          componentRestrictions: { country: this.countries },
          types: [this.addressType], // 'establishment' / 'address' / 'geocode'
        },
      );
    }

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      let streetNumber = '';
      this.addressDetails.suite = place.address_components
        ?.filter(
          (add: any) =>
            add.types.filter((type: any) => type === 'subpremise').length > 0,
        )[0]
        ?.long_name.replace('suite ', '');
      streetNumber = place.address_components?.filter(
        (add: any) =>
          add.types.filter((type: any) => type === 'street_number').length > 0,
      )[0]?.long_name;
      this.addressDetails.streetName = place.address_components?.filter(
        (add: any) =>
          add.types.filter((type: any) => type === 'route').length > 0,
      )[0]?.long_name;
      this.addressDetails.city = place.address_components?.filter(
        (add: any) =>
          add.types.filter((type: any) => type === 'locality').length > 0,
      )[0]?.long_name;
      this.addressDetails.state = place.address_components?.filter(
        (add: any) =>
          add.types.filter(
            (type: any) => type === 'administrative_area_level_1',
          ).length > 0,
      )[0]?.long_name;
      this.addressDetails.zipCode = place.address_components?.filter(
        (add: any) =>
          add.types.filter((type: any) => type === 'postal_code').length > 0,
      )[0]?.long_name;
      this.addressDetails.country = place.address_components?.filter(
        (add: any) =>
          add.types.filter((type: any) => type === 'country').length > 0,
      )[0]?.long_name;
      if (streetNumber !== undefined) {
        this.addressDetails.streetName =
          streetNumber + ' ' + this.addressDetails.streetName;
      }
      this.isValidAddress = true;
      this.invokeEvent(this.addressDetails);
      this.cdr.detectChanges();
    });
  }

  invokeEvent(address: AddressDetails) {
    this.setAddress.emit(address);
  }

  ShowInvalidAddressWarning(eventFrom: string) {
    this.isValidAddress = !this.isValidAddress;
    if (eventFrom === 'clickhere') {
      this.allowManualAddress.emit(true);
    }
  }

  CheckInput(event: Event) {
    const val = (event.target as HTMLInputElement).value;
    if (!val) {
      const address = {} as AddressDetails;
      this.setAddress.emit(address);
    }
  }

  ResetAddress() {
    this.isValidAddress = true;
    this.addresstext.nativeElement.value = '';
  }

  RemoveAddress() {
    this.MakeAddressEmpty();
    this.ResetAddressValue.emit();
  }

  MakeAddressEmpty() {
    this.autocompleteInput = '';
  }

  ngOnDestroy() {
    this.googleService.address.next('');
  }
}
