import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { PortalRegistrationService } from '../modules/portal-registration/services/portal-registration.service';
@Directive({
  selector: '[validateEmail]',
})
export class ValidateEmailDirective implements OnInit, OnDestroy {
  @Output() CheckEmailValidation = new EventEmitter<boolean>();
  subject: Subject<any> = new Subject();

  constructor(
    public el: ElementRef,
    private portalRegistrationService: PortalRegistrationService,
    private cdr: ChangeDetectorRef,
  ) {}

  @HostListener('focusout', ['$event'])
  focusout(event: KeyboardEvent) {
    const e = <any>event;
    const val = this.el.nativeElement.value;
    if (val.length > 0 && e.inputType !== 'deleteContentBackward') {
      this.subject.next('');
    }
  }

  ngOnInit() {
    this.subject.pipe(debounceTime(100)).subscribe(() => {
      this.ValidateEmail(this.el.nativeElement.value);
    });
  }

  ValidateEmail(phoneNumber: string) {
    this.portalRegistrationService
      .ValidateEmailAsync(phoneNumber.replaceAll('-', ''))
      .then((res) => {
        this.CheckEmailValidation.emit(res);
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }
}
