import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  isModalActive = false;
  isModalActiveInitialState: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(this.isModalActive);
  isModalActiveInitialStateData: Observable<boolean> =
    this.isModalActiveInitialState.asObservable();
  isEditFromListModalActive: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  ToggleModalWIndow(data: boolean) {
    this.isModalActiveInitialState.next(data);
    this.isModalActive = data;
    return data;
  }

  modalWidth!: string;
  modalWidthStateInitialState: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.modalWidth);
  modalWidthInitialStateData: Observable<string> =
    this.modalWidthStateInitialState.asObservable();
  ToggleModalWidthData(data: string) {
    this.modalWidthStateInitialState.next(data);
    this.modalWidth = data;
    return data;
  }

  modalHeight!: string;
  modalHeightStateInitialState: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.modalHeight);
  modalHeightInitialStateData: Observable<string> =
    this.modalHeightStateInitialState.asObservable();
  ToggleModalHeightData(data: string) {
    this.modalHeightStateInitialState.next(data);
    this.modalHeight = data;
    return data;
  }

  modalHeading!: string;
  modalHeadingStateInitialState: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.modalHeading);
  modalHeadingInitialStateData: Observable<string> =
    this.modalHeadingStateInitialState.asObservable();
  ToggleModalHeadingData(data: string) {
    this.modalHeadingStateInitialState.next(data);
    this.modalHeading = data;
    return data;
  }

  modalContentComponent!: any;
  modalContentComponentInitialState: BehaviorSubject<any> =
    new BehaviorSubject<any>(this.modalContentComponent);
  modalContentComponentInitialStateData: Observable<any> =
    this.modalContentComponentInitialState.asObservable();
  ToggleModalContentComponent(data: any) {
    this.modalContentComponentInitialState.next(data);
    this.modalContentComponent = data;
    return data;
  }

  ModalProps(
    isModalActive: boolean,
    modalWidth: string,
    modalHeight: string,
    modalHeading: string,
    modalContentComponent: any,
  ) {
    this.modalWidth = modalWidth;
    this.modalHeight = modalHeight;
    this.modalHeading = modalHeading;
    this.modalContentComponent = modalContentComponent;
  }

  isSavedContactModalActiveInitialState: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isSavedContactModalActiveInitialStateData: Observable<boolean> =
    this.isSavedContactModalActiveInitialState.asObservable();
  ToggleSavedContactModal(data: boolean) {
    this.isSavedContactModalActiveInitialState.next(data);
  }

  resetModalState(): void {
    this.isModalActive = false;
    this.isModalActiveInitialState.next(false);
    this.modalWidth = '';
    this.modalWidthStateInitialState.next('');
    this.modalHeight = '';
    this.modalHeightStateInitialState.next('');
    this.modalHeading = '';
    this.modalHeadingStateInitialState.next('');
    this.modalContentComponent = null;
    this.modalContentComponentInitialState.next(null);
    this.isEditFromListModalActive.next(false);
    this.isSavedContactModalActiveInitialState.next(false);
  }
}
